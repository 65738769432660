import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Contact.css";
import { submitContactUs } from "../../Redux/Action/Contact/contactAction";
import { toast } from "react-toastify";
import { EXPLORE_APP } from "../../Routes/RoutesConstant";
import { useNavigate } from "react-router";
import ScrollAnimation from "react-animate-on-scroll";
// import MobileLeft from "../../assets/images/mobile2.png";
// import MobileRight from "../../assets/images/mobile.png";

const Contact = () => {
  const [isFirst, setIsFirst] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Name Required"),
      email: Yup.string().trim().email("Invalid email").required("Email Required"),
      subject: Yup.string().trim().required("Subject Required"),
      message: Yup.string().trim().required("Message Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let body = JSON.stringify({
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
        platform: 1,
      });

      let response = await dispatch(submitContactUs(body));
      // console.log("---response", response);
      if (response.type == "GET_CONTACTUS_SUCCESS") {
        toast.success(
          "Thank you for contacting us, we will get in touch with you soon !!!"
        );
        resetForm();
      } else {
        toast.error("Somethings went wrong!");
      }
    },
  });

  return (
    <>
      <section className="green-heading">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Contact Us</h2>
              <p>Bridging the gap between local businesses and customers.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <label>Name*</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter your Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label>Email*</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <label className="input_label">Subject*</label>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Enter Subject"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <div
                        className="invalid-feedback"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.subject}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    <label className="input_label">Message*</label>
                    <textarea
                      placeholder="Enter Message"
                      name="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                    />
                    {formik.touched.message && formik.errors.message ? (
                      <div
                        className="invalid-message"
                        style={{ display: "inline-block" }}
                      >
                        {formik.errors.message}
                      </div>
                    ) : null}
                    <button className="sbmt-form">Submit Request</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="get-in-touch">
                <h4>Get In Touch</h4>
                <p>
                  Have a question for the Brigiz team? We’d love to hear from
                  you, so don’t hesitate to give us a call, send an email, or
                  write to us today!
                </p>
                <ul>
                  <li className="git-head">
                    <img src="images/call-calling.svg" /> Call Us:
                  </li>
                  <a
                    href={`tel:+14044005399`}
                    style={{ textDecoration: "none" }}
                  >
                    <li className="git-txt">+1 404-400-5399</li>
                  </a>
                </ul>
                <ul>
                  <li className="git-head">
                    <img src="images/sms.svg" /> Drop an email:
                  </li>
                  <a
                    href={`mailto:services@brigiz.com`}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <li className="git-txt">services@brigiz.com</li>
                  </a>
                </ul>
                {/* <ul>
                  <li className="git-head">
                    <img src="images/map.svg" /> Reach Us:
                  </li>
                  <li className="git-txt">
                    299 Hespeler Road, Unit 102 Cambridge, ON N1R 3H8
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="download-app">
        <div className="container">
          <div className="row da-back">
            <div className="col-md-6">
              <div className="da-text">
                <h4>
                  <img src="images/suffixes3.svg" className="suffixes3" />
                  Download Today &amp; Let’s find more{" "}
                  <img src="images/green-suf2.png" className="green-suf2" />
                  features to explore.
                </h4>
                <p>
                  Brigiz is dedicated to connecting customers to local
                  businesses and services. Let's find a space for your business
                  or get you connected with a service near you!
                </p>
                <span>
                  Want to know more about the App?
                  <img
                    src="images/arrow-img2.png"
                    className="arrw-image2 contact_arrow moving_horizontal"
                  />
                </span>
                <button
                  className="byb-explore-btn"
                  onClick={() => navigate(EXPLORE_APP)}
                >
                  Explore
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="da-image">
                {/* <img src="images/da-img.png" className="img-fluid" /> */}
                <div className="footer_animatio_img">
                  <ScrollAnimation animateIn="fadeInLeft">
                    <img
                      src="images/mobile2.png"
                      className="img-fluid  slide_right  wow fadeInLeft"
                    />
                  </ScrollAnimation>

                  <div className="footer_animation_slide_wrap">
                    <ScrollAnimation animateIn="fadeInRight">
                      <img
                        src="images/mobile.png"
                        className="img-fluid slide_left  wow fadeInRight"
                      />
                    </ScrollAnimation>
                  </div>
                </div>
                <img src="images/img62.png" className="img63" />
                <img src="images/img62.png" className="img64" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
