import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
import { generatePaymentIntent } from "../API/ApiService";
import { STRIPE_KEY, STRIPE_LIVE_KEY } from "../Utils/constants";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Checkout = (props) => {
  //   const options = {
  //     // passing the client secret obtained in step 2
  //     clientSecret: props.clientSecret,
  //     //   "pi_3L19dSALne365GO90cx5PCpz_secret_xssrzO4MB1f59Cd8lj8f69aiQ",
  //     // Fully customizable with appearance API.
  //     appearance: {
  //       /*...*/
  //     },
  //   };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: props.clientSecret,
        appearance: {
          variables: {
            colorPrimary: "#0570de",
            colorBackground: "#ffffff",
            colorText: "#30313d",
            colorDanger: "#df1b41",
            fontFamily: "Ideal Sans, system-ui, sans-serif",
            spacingUnit: "6px",
            borderRadius: "4px",
            // See all possible variables below
          },
        },
      }}
    >
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default Checkout;
