import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_DETAILS_SUCCESS,
} from "../Action/actionTypes";
const initialState = {
  loginDataSuccess: {},
  loginDataError: {},
  // accessToken: "",
  // refreshToken: "",
  // logoutDetails: "",
};
const LoginData = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      // let accessToken = action?.payload?.data?.access_token
      // let refreshToken = action?.payload?.data?.refresh_token
      // console.log('reducers--',action?.payload.data);
      return {
        ...state, // not value lose
        loginDataSuccess: action?.payload.data,
        // accessToken: accessToken,
        // refreshToken: refreshToken,
        loginDataError: {},
      };
    case LOGIN_ERROR:
      return {
        ...state, // not value lose
        loginDataError: action?.payload,
        loginDataSuccess: {},
      };

    case LOGOUT_SUCCESS:
      return {
        initialState,
      };

    default:
      return state;
  }
};

export default LoginData;
