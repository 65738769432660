import React from "react";
import "./events.css";

const Events = () => {
  return (
    <div className="">
      <div className="event-top">
        <div className="event-bg">
          <div className="event-win-block">
            <h2 className="event-win-label">
              Win <span className="event-win-price">$5000 </span> For Your
              Business!
            </h2>

            <h3 className="event-win-sub-label">
              Create a Pro account on Brigiz today for your chance to <br /> win
              one of 7 $5,000 Business Giveaways
            </h3>
          </div>
          <div className="event-img-block">
            <a href="https://investfest.com/tickets/" target="_blank">
              <img src="/images/Get-Tickets.png" className="sticker" />
            </a>
            <img src="/images/Ellipse-red.svg" className="red-fade" />

            <img
              src="/images/money.png"
              alt="collect"
              className="money-bundle"
            />
            <img src="/images/iphone.png" alt="phone" className="phone" />
            <img src="/images/cash-money.png" alt="phone" className="money" />
            {/* <img src="/images/labelgreen.svg" alt="label" className="label" /> */}
            <div className="label">
              <div class="banner">
                <div class="text">
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                  <span className="label-text">
                    $5,000.00 Business Giveaways.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="step-section_bg">
        <div className="step-section-wrap">
          <h2 className="step-section-subTitle">
            Entering the
            <span style={{ color: "#349C52", position: "relative" }}>
              <img src="images/green-suf.png" className="green-suf" />
              {' '}giveaway{' '}
            </span>
            is simple
          </h2>
          <img src="images/img62.png" className="step-deco-img" />
          <div className="step-section-block">
            <div className="step-section_bg"></div>
            <div className="step-section-card">
              <div
                style={{
                  color: "#349C52",
                  fontSize: "54px",
                  fontWeight: "700",
                  paddingBottom: "20px",
                }}
              >
                01
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  #
                </span>
              </div>
              <div style={{ fontSize: "16px", fontWeight: "400" }}>
                Ensure your business is registered as a Pro on Brigiz. <br />
                <span style={{ paddingTop: "20px", display: "block" }}>
                  If you haven't done so already, you can easily create your
                  business profile by downloading the app on your Android or
                  Apple device.
                </span>
              </div>
            </div>
            <div className="step-section-card">
              <div
                style={{
                  color: "#349C52",
                  fontSize: "54px",
                  fontWeight: "700",
                  paddingBottom: "20px",
                }}
              >
                02
                <span style={{ fontSize: "20px", fontWeight: "500" }}>#</span>
              </div>
              <div>
                Complete your business profile with accurate and up-to-date
                information.
                <br />
                <span style={{ paddingTop: "20px", display: "block" }}>
                  The business must be providing a service or a product that can
                  be purchased by customers.
                </span>
              </div>
            </div>
            <div className="step-section-card">
              <div
                style={{
                  color: "#349C52",
                  fontSize: "54px",
                  fontWeight: "700",
                  paddingBottom: "20px",
                }}
              >
                03
                <span style={{ fontSize: "20px", fontWeight: "500" }}>#</span>
              </div>
              <div>
                You must add the tag ‘InvestFest24’ on your business profile.
              </div>
            </div>
            <img src="images/suffixes.svg" className="card-suffixes" />
            <img src="images/img62.png" className="step-deco-img2" />
          </div>
          <div className="widget-wrap">
            <div className="widget-content">
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: "900",
                  textAlign: "center",
                }}
              >
                That's it! 👍
              </h3>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Once you meet these requirements, you'll automatically be
                entered into the drawing for one of 7 chances to win the $5,000
                Business Giveaways.
              </p>
            </div>
          </div>
          <div className="event-condition-content-wrap">
            <h3 className="event-condition-title">*Conditions Apply*</h3>
            You must be in attendance at InvestFest 2024 or have a valid ticket
            to be eligible for the first two (2) giveaways. $5000 will be
            awarded to an attending business owner and another $5000 will be
            awarded to a vendor. The five (5) remaining giveaways will be open
            to businesses nationwide that are registered as Pro Subscribers. A
            winner will be announced on the 25th of each month on Brigiz App.
          </div>
        </div>
        <img src="images/fade.svg" className="step-deco-fade" />
        <img src="images/fade2.svg" className="step-deco-fade2" />
      </div>

      {/* <div className="gift-widget-wrap">
        <div className="gift-widget-block">
          <div className="img-wrap">
            <img src="images/Group 72846@2x.svg" className="gift" />
       
          </div>
          <div className="content-wrap">
            <h2 className="gift-section-subtitle">
              Purpose of
              <span style={{ color: "#349C52", position: "relative" }}>
                <img
                  src="images/green-suf.png"
                  style={{ position: "absolute", bottom: "0", width: "100%" }}
                />
                Giveaways
                <img
                  src="images/suffixes2.svg"
                  style={{
                    position: "absolute",
                    right: "-15%",
                    top: "-15%",
                    width: "20%",
                  }}
                />
              </span>
            </h2>
            <p style={{ fontSize: "16px", fontWeight: "400" }}>
              These grant can be used to fund various aspects of your business,
              whether it's expanding your marketing efforts, investing in new
              equipment, or launching an exciting project.
            </p>
            <p style={{ fontSize: "16px", fontWeight: "400" }}>
              It's entirely up to you! Imagine the possibilities that this
              financial boost could bring to your business.
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className="widget-note-container">
        <div className="widget-note-Wrapper">
          <h4
            style={{
              fontSize: "20px",
              fontWeight: "800",
              fontStyle: "italic",
              color: "#fff",
            }}
          >
            Note:
          </h4>
          <div className="widget-note-box">
            <div className="widget-note-leftSide">
              <div style={{ padding: "12px 0px" }}>
                Deadline to win at InvestFest24 is:
              </div>
              <div
                style={{
                  backgroundColor: "#349C5233",
                  display: "inline-block",
                  padding: " 8px",
                  fontSize: "16px",
                  borderRadius: "4px",
                }}
              >
                Midnight August 24th, 2024.
              </div>
              <div style={{ padding: "12px 0px" }}>3 Grants distributed</div>
            </div>
            <div className="widget-note-rightSide">
              <div style={{ padding: "12px 0px" }}>
                The final deadline to win any grants is:
              </div>
              <div
                style={{
                  backgroundColor: "#349C5233",
                  display: "inline-block",
                  padding: " 8px",
                  fontSize: "16px",
                  borderRadius: "4px",
                }}
              >
                September 30, 2024
              </div>
              <div style={{ padding: "12px 0px" }}>
                4 Grants distributed each month until end of the year December
                31st, 2024
              </div>
            </div>
          </div>
          <div
            style={{ padding: "12px", color: "#fff", display: "inline-block" }}
          >
            📢 All winners announced in our app. 🎊
          </div>
        </div>
      </div> */}
      <div className="store-widget-container">
        <div className="store-widget-wrap">
          <div className="store-widget-content-wrap">
            <h2 className="store-widget-subTitle">
              We look forward to seeing your business thrive with
              <span style={{ color: "#349C52" }}> Brigiz! </span> 🤘🎊
            </h2>
            {/* <p className="store-widget-content">
              We encourage you to take advantage of this fantastic opportunity
              and be a part of the growing community of businesses benefiting
              from Brigiz. Don't miss chance to win one of seven $5000 Business
              Grant!
            </p> */}
          </div>
          <div className="store-widget-img-wrap">
            <img src="images/store.svg" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
      {/* <div className="widget-container">
        <div className="store-widget-block">
          <p style={{ fontSize: "20px", fontWeight: "400" }}>
            If you have any questions or need assistance with your business
            profile or the giveaway,
            <br /> Our dedicated support team is here to help.
          </p>
          <div className="store-widget-contact">
            Simply reach out to us at: 404-400-5399.
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Events;
