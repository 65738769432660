import { LATITUDE, LONGITUDE } from "../../Utils/constants";
import {
  LOCATION_LIST_ERROR,
  LOCATION_LIST_SUCCESS,
  SELECT_LOCATION,
  SET_LAT_LNG,
} from "../Action/actionTypes";

const initialState = {
  stateList: [],
  selectedState: {},
  latLng: {
    lat: '',
    lng: '',
  },
};
const LocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_LIST_SUCCESS:
      return {
        ...state, // not value lose
        stateList: action.payload,
      };
    case LOCATION_LIST_ERROR:
      return {
        ...state, // not value lose
        stateList: [],
      };

    case SELECT_LOCATION:
      return {
        ...state,
        selectedState: action.payload,
      };
    case SET_LAT_LNG:
      return {
        ...state,
        latLng: action.payload,
      };

    default:
      return state;
  }
};

export default LocationReducer;
