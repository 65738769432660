
import { GET_BUSINESS_FEATURE_SUCCESS,GET_BUSINESS_FEATURE_ERROR,
  GET_POPULAR_CAT_SUCCESS,GET_POPULAR_CAT_ERROR,
  GET_LOCAL_HIGHTLIGHT_SUCCESS,GET_LOCAL_HIGHTLIGHT_ERROR,
  BUSINESS_LIST_SUCCESS,BUSINESS_LIST_ERROR
} from "../Action/actionTypes";
const initialState = {
featureBusinessListSuccess:{},
featureBusinessListError:{},
papularCategorySuccess:{},
papularCategoryError:{},
localHightlightSuccess:{},
localHightlightErorr:{},
businessListSuccess:{},
businessListError:{},
businessSearchSuccess:{}

}
const BusinessData = (state = initialState, action) => {
switch (action.type) {
case GET_BUSINESS_FEATURE_SUCCESS:
  return {
    ...state, // not value lose
    featureBusinessListSuccess: action?.payload,
    featureBusinessListError:{}
    };
case GET_BUSINESS_FEATURE_ERROR:
    return {
    ...state, // not value lose
    featureBusinessListError: action?.payload,
    featureBusinessListSuccess:{}
    };
case GET_POPULAR_CAT_SUCCESS:
  return {
    ...state, // not value lose
    papularCategorySuccess: action?.payload,
    papularCategoryError:{}
    };
case GET_POPULAR_CAT_ERROR:
    return {
    ...state, // not value lose
    papularCategoryError: action?.payload,
    papularCategorySuccess:{}
  };

case GET_LOCAL_HIGHTLIGHT_SUCCESS:
  return {
  ...state, // not value lose
  localHightlightSuccess: action?.payload,
  papularCategoryError:{}
};
case GET_LOCAL_HIGHTLIGHT_ERROR:
  return {
  ...state, // not value lose
  localHightlightError: action?.payload,
  localHightlightSuccess:{}
};

case BUSINESS_LIST_SUCCESS:
  return {
  ...state, // not value lose
  businessListSuccess: action?.payload,
  businessListError:{}
};
case BUSINESS_LIST_ERROR:
  return {
  ...state, // not value lose
  businessListError: action?.payload,
  businessListSuccess:{}
};
case "SEARCH_BUSINESS_SUCCESS":
  return {
    ...state,
    businessSearchSuccess:action
  }

default:
  return state
}
}


export default BusinessData;