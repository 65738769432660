import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_DETAILS_SUCCESS,
} from "../actionTypes";
import { loginApi } from "../../../API/ApiService";
import { loadingAction } from "../loadingAction";

export const login = (data) => {
  return (dispatch) => {
    dispatch(loadingAction(true));
    return loginApi(data)
      .then((response) => {
        dispatch(loadingAction(false));
        dispatch({ type: LOGOUT_DETAILS_SUCCESS, payload: {} });
        return dispatch({ type: LOGIN_SUCCESS, payload: response });
      })
      .catch((err) => {
        dispatch(loadingAction(false));
        throw err;
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    // dispatch(loadingAction(true));
    // localStorage.clear();
    localStorage.removeItem("accessToken");
    dispatch({ type: LOGOUT_SUCCESS, payload: "LOGOUT" });
    return dispatch({ type: LOGOUT_DETAILS_SUCCESS, payload: "LOGOUT" });
  };
};
