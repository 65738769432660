import { combineReducers } from "redux";
import businessData from "./businessReducers";
import loadingReducers from "./loadingReducers";
import LoginData from "./loginReducers";
import locationReducer from "./locationReducer";

const rootReduser = combineReducers({
  LoginData,
  businessData,
  loadingReducers,
  locationReducer,
});

export default rootReduser;
