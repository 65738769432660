import React, { useState, useEffect } from "react";
import { Link, useNavigate, Redirect } from "react-router-dom";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import Cookies from "js-cookie";
import swal from "sweetalert";
import { login } from "../../Redux/Action/LoginAction/loginAction";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { setItemLocalStorage } from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";

const Login = () => {
  const navigate = useNavigate();

  // const dispatch = useDispatch();
  // const auth = useSelector((state) => state.auth);
  // const [isLogin, setIsLogin] = useState(false);

  // useEffect(() => {
  //   console.log("loggin----------", auth);
  //   if (isLogin) {
  //     console.log("----------", auth);
  //     if (
  //       auth?.authUserSuccess != "" &&
  //       auth?.authUserSuccess?.status === 200
  //     ) {
  //       navigate(-1);
  //     } else {
  //       navigate("/login");
  //     }
  //   }
  // }, [isLogin]);

  // const formik = useFormik({
  //   initialValues: {
  //     password: "",
  //     email: "",
  //   },
  //   validationSchema: Yup.object({
  //     password: Yup.string().required("Password Required"),
  //     email: Yup.string().email("Invalid email").required("Email Required"),
  //   }),
  //   onSubmit: async (values, { resetForm }) => {
  //     let body = JSON.stringify({
  //       email: values.email,
  //       password: values.password,
  //       platform: "android",
  //       os_version: "v1.0",
  //       app_version: "v.4.5",
  //       user_timezone: "GMT+5:30",
  //     });
  //     await dispatch(login(body));
  //     setIsLogin(true);
  //   },
  // });

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: Cookies.get("email"),
    password: Cookies.get("password"),
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  // ======= submit Login request ==============
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let body = JSON.stringify({
      email: values.email,
      password: values.password,
      platform: "android",
      os_version: "v1.0",
      app_version: "v.4.5",
      user_timezone: "GMT+5:30",
    });
    // console.log("body--", body);
    try {
      const response = await dispatch(login(body));
      const loginDataSuccess = response?.payload;
      if (loginDataSuccess) {
        if (loginDataSuccess?.data.status == 200) {
          if (loginDataSuccess?.data?.user?.is_deleted == 0) {
            if (loginDataSuccess?.data?.user?.user_state === '3' || loginDataSuccess?.data?.user?.user_state === '4') {
              setItemLocalStorage(
                "accessToken",
                loginDataSuccess.data.access_token
              );
              setItemLocalStorage(
                "refreshToken",
                loginDataSuccess.data.refresh_token
              );
              setItemLocalStorage("id", loginDataSuccess.data.user.id);
              if (
                loginDataSuccess?.data != "" &&
                loginDataSuccess?.data.status === 200
              ) {
                navigate(-1);
              } else {
                navigate("/login");
              }
            }
          } else {
            swal({
              title: "Inactive User!",
              text: 'User is either Inactive or Deleted',
              buttons: false,
              icon: "error",
              timer: 3000,
            });
          }
        } else {
          swal({
            title: "error!",
            text: 'Please complete your business details from the mobile app and try again',
            buttons: false,
            icon: "error",
            timer: 3000,
          });
        }
      } else {
        swal({
          title: "error!",
          text: loginDataSuccess?.data?.message,
          buttons: false,
          icon: "error",
          timer: 2000,
        });
      }
    } catch (err) {
      swal({
        title: "Invalid Credentials",
        text: err?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };

  return (
    <div className="login_first">
      {/* <div className="pop_header">
        <img
          src="images/login-logo.png"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <p className="dwnld_app2_instr">
          **login is only for business owners**
        </p>
      </div> */}
      <div className="login_wrap_heading">
        <p className="dwnld_app2_instr">
          **login is only for business owners**
        </p>
      </div>
      <div className=" login_form_wrap">
        <form onSubmit={handleSubmit} className="login_form">
          <h4 className="login_form_label">Login to your Account</h4>
          <label className="login_form_email">Email Address</label>
          <input
            className="login_form_input"
            type="text"
            name="email"
            onChange={handleInputChange}
          />

          <label className="login_form_email">Password</label>
          <input
            className="login_form_input"
            type="password"
            name="password"
            onChange={handleInputChange}
          />
          <button className="login_form_btn">Login</button>
        </form>

        <div className="dwnld_app2">
          <h3 className="dwnld_app2_h3">Want to create an account?</h3>
          <span className="dwnld_app2_span">
            Please <font>Download</font> and
            <font> Install</font> Brigiz App
          </span>
          <div className="download_btns">
            <button
              className="g_play_btn"
              onClick={() => window.open(ANDROID_APP_LINK, "_")}
            >
              <div className="g_play_btn_wrap">
                <div className=" btn_img">
                  <img src="images/play-button.svg" style={{ width: "45px" }} />
                </div>
                <div className=" btn_txt">
                  <span className="btn_text_span">GET IT ON</span>
                  <p className="btn_text_p">Google Play</p>
                </div>
              </div>
            </button>
            <button
              className="g_play_btn"
              onClick={() => window.open(IOS_APP_LINK, "_")}
            >
              <div className="g_play_btn_wrap">
                <div className=" btn_img">
                  <img src="images/apple.svg" style={{ width: "45px" }} />
                </div>
                <div className=" btn_txt">
                  <span className="btn_text_span">Download on the</span>
                  <p className="btn_text_p">App Store</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
