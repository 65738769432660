import React, { useCallback, useMemo, useRef, useState } from "react";
// import Skeleton from "@material-ui/lab/Skeleton";
import { useEffect } from "react";
// import Card from "./Card";
import SkeletonLoader from "./skeleton-loader/Skeleton";
// import Slider from "./Slider";
// import { CardBlock, SectionContainer, SkeletonContainer } from ".";

const GridCardWrapper = ({
  data,
  isLoading = false,
  children,
  blockWidth = 380,
  blockHeight = 300,
  blockMargin = 16,
  title = "",
  showSlider = true,
}) => {
  const [noOfCards, setNoOfCards] = useState(1);
  const [rowCount, setNoOfRows] = useState(1);
  const [margin, setMargin] = useState(0);
  const [screenWidth, setWidth] = useState();
  const [currentWidth, setBlockWidth] = useState(blockWidth);

  const Item = children;

  useEffect(() => {
    const elem = document.getElementById("grid-wrapper");
    setWidth(elem.getBoundingClientRect().width);
  }, []);

  const resize = useCallback(() => {
    const elem = document.getElementById("grid-wrapper");
    setWidth(elem.getBoundingClientRect().width);
  }, []);

  useEffect(() => {
    if (screenWidth < blockWidth) {
      setBlockWidth(screenWidth);
    } else {
      setBlockWidth(blockWidth);
    }
  }, [screenWidth, blockWidth]);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  useEffect(() => {
    // if (sectionRef.current) {
    let width = screenWidth;
    let noOfCardsInEachRow = parseInt(width / currentWidth);
    const calMarginWidth = (noOfCardsInEachRow - 1) * blockMargin;
    const actualCardCount = parseInt((width - calMarginWidth) / currentWidth);
    const noOfExtraRow = data.length % actualCardCount;
    const rightMargin =
      (width - actualCardCount * currentWidth) / (actualCardCount - 1);
    let noOfRows = parseInt(data.length / actualCardCount);
    if (noOfExtraRow > 0) {
      noOfRows += 1;
    }
    setNoOfCards(actualCardCount);
    setMargin(screenWidth < 580 ? blockMargin : rightMargin);
    setNoOfRows(noOfRows);
    // setVisible(actualCardCount * 2);
    // }
  }, [screenWidth, data]);

  const getMappedItem = useCallback(
    (item, i) => {
      return (
        <Item
          key={i}
          width={
            noOfCards === 2 ? "50%" : noOfCards === 1 ? "100%" : blockWidth
          }
          height={blockHeight}
          marginLeft={i === 0 ? 0 : noOfCards === 1 ? "0" : margin}
          data={item}
        />

        // <div
        //   className="nib-box"
        //   style={{
        //     width:
        //       noOfCards === 2 ? "50%" : noOfCards === 1 ? "100%" : blockWidth,
        //     height: blockHeight,
        //     marginLeft:
        //       i === 0
        //         ? 0
        //         : noOfCards === 2
        //         ? "20px"
        //         : noOfCards === 1
        //         ? "0"
        //         : margin,
        //     marginBottom: "20px",
        //   }}
        //   key={i}
        // >
        //   <div className="nib-box-img">
        //     <img
        //       src={
        //         item?.userDetail?.subscription_id === 1
        //           ? "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
        //           : item?.businessImage?.[0]?.image ||
        //             "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
        //       }
        //       style={{ width: "100%", objectFit: "cover" }}
        //     />
        //   </div>
        //   <h4>{item.business_name}</h4>
        //   <ul>
        //     {Array.from(Array(item.userReview), (e, i) => {
        //       return (
        //         <li key={i}>
        //           <i className="fa-solid fa-star" />
        //         </li>
        //       );
        //     })}
        //   </ul>
        //   <button
        //     className="view-detail-btn"
        //     disabled={item?.userDetail?.subscription_id === 1}
        //     onClick={() => {
        //       handleViewDetail && handleViewDetail(item.id);
        //     }}
        //   >
        //     {item?.userDetail?.subscription_id === 1 && (
        //       <img
        //         src="images/lock.svg"
        //         width={18}
        //         style={{ marginRight: "8px" }}
        //       />
        //     )}
        //     View Details
        //   </button>
        // </div>
        //   <Item
        //     key={i}
        //     item={item}
        //     className="card"
        //     margin={
        //       data.length < noOfCards
        //         ? blockMargin
        //         : i === 0
        //         ? 0
        //         : `${margin}px`
        //     }
        //     index={i}
        //     width={blockWidth}
        //     height={blockHeight}
        //     isSlider={isSlider}
        //   />
      );
    },
    [blockWidth, blockHeight, blockMargin, data.length, margin, noOfCards]
  );

  const finalData = data;

  return (
    <>
      <div id="grid-wrapper">
        {Array.from({ length: rowCount }, (_, index) => (
          <div
            className="section_row"
            key={index}
            style={{ display: "flex", flexWrap: "nowrap" }}
          >
            {finalData
              .slice(index * noOfCards, noOfCards * index + noOfCards)
              .map((item, i) => getMappedItem(item, i))}
          </div>
        ))}
        {isLoading && (
          <div id="grid-wrapper">
            {Array.from({ length: 1 }, (_, index) => (
              <div
                className="section_row"
                key={index}
                style={{ display: "flex", flexWrap: "nowrap" }}
              >
                {Array.from({ length: noOfCards }, (_, i) => (
                  <div
                    // className="slider-skeleton-block"
                    key={i}
                    style={{
                      height: 380,
                      width: 320,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft:
                        i === 0 ? 0.0 : noOfCards === 1 ? "0" : margin,
                    }}
                  >
                    <SkeletonLoader height={160} />
                    <SkeletonLoader height={30} />
                    <SkeletonLoader height={30} width={120} />
                    <SkeletonLoader height={20} />
                    <SkeletonLoader height={20} />
                    <SkeletonLoader height={40} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default GridCardWrapper;
