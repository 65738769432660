import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import {
  featureBusinessList,
  getPopularCatList,
  getBusinessTypeList,
  searchBusiness,
  webSearchBusinessApi,
} from "../../Redux/Action/BusinessAction/businesAction";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import CustomSlider from "../../Component/Slider";
import "./Home.css";
import ScrollAnimation from "react-animate-on-scroll";
import Card from "../../Component/Card";
import ClickAwayListener from "react-click-away-listener";
import {
  BUSINESS_DETAILS,
  BUSINESS_LIST,
  EVENTS,
  EXPLORE_APP,
} from "../../Routes/RoutesConstant";
import {
  getDailyDeals,
  getFeatureBusiness,
  getNewBuisnes,
} from "../../API/ApiService";
import { loadingAction } from "../../Redux/Action/loadingAction";
import { skeletonAction } from "../../Redux/Action/loadingAction";
import { getItemLocalStorage } from "../../Utils/localStroge";
import {
  DAILY_DEAL,
  FEATURE_BUSINESS,
  HOME_PAGE_DATA_LIST,
  LATITUDE,
  LOCAL_HIGHLIGHT,
  LONGITUDE,
  NEW_BUSINESS,
} from "../../Utils/constants";
import { usePosition } from "../../usePosition";
import EmptyMessage from "../../Component/EmptyMessage";
import SelectBox from "../../Component/select-box/index";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scollToRef = useRef();
  const banner = useRef();

  const [typeName, setTypeName] = useState("");

  const businessData = useSelector((state) => state.businessData);
  const loadingRequest = useSelector((state) => state.loadingReducer);
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const [businessType, setsetpbusinessType] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [typeSelect, setTypeSelect] = useState();
  const [daliyDealsList, setDailyDeals] = useState([]);
  const [newBusinessList, setNewBusinesses] = useState([]);
  const [featureBusinessList, setFeatureBusinessList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [screenWidth, setWidth] = useState(window.innerWidth);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [value, setValue] = useState("");
  const resize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  const { stateList, selectedState } = useSelector(
    (state) => state.locationReducer
  );
  const {
    latLng: { lat, lng },
  } = useSelector((state) => state.locationReducer);

  const currentState = stateList.find((s) => s.state_code === selectedState);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  useEffect(async () => {
    const response = await dispatch(getBusinessTypeList());
    if (response?.type === "BUSINESS_TYPE_SUCCESS") {
      setsetpbusinessType(response?.payload);
    }
  }, []);

  useEffect(() => {
    if (businessData?.localHightlightSuccess?.BusinessList?.length > 0) {
      getAllHomePageData();
    }
  }, [lat, lng, businessData]);

  const getAllHomePageData = () => {
    const body = {
      page: 1,
      text: "",
      fetchtype: "home",
      user_uuid: "",
      limit: 10,
      state: currentState?.state || "",
      state_code: currentState?.state_code || "",
      lat,
      lng,
    };
    dispatch(skeletonAction(true, DAILY_DEAL));
    const promise1 = new Promise((resolve) => {
      resolve(
        getDailyDeals(body)
          .then((res) => {
            setDailyDeals(res.data.results);
            dispatch(skeletonAction(false, DAILY_DEAL));
          })
          .catch(() => {
            dispatch(skeletonAction(false, DAILY_DEAL));
          })
      );
    });

    dispatch(skeletonAction(true, FEATURE_BUSINESS));
    const promise2 = new Promise((resolve) => {
      resolve(
        getFeatureBusiness(body)
          .then((res) => {
            setFeatureBusinessList(res.data.result);
            dispatch(skeletonAction(false, FEATURE_BUSINESS));
          })
          .catch(() => {
            dispatch(skeletonAction(false, FEATURE_BUSINESS));
          })
      );
    });

    dispatch(skeletonAction(true, NEW_BUSINESS));
    const promise3 = new Promise((resolve) => {
      resolve(
        getNewBuisnes(body)
          .then((res) => {
            setNewBusinesses(res.data.result);
            dispatch(skeletonAction(false, NEW_BUSINESS));
          })
          .catch(() => {
            dispatch(skeletonAction(false, NEW_BUSINESS));
          })
      );
    });
    return Promise.all([promise1, promise2, promise3]);
  };

  const papularCatSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const newBusinessSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // centerMode: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const localHightSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    // responsive: [
    //   {
    //     breakpoint: 700,
    //     settings: {
    //       arrows: false,
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 500,
    //     settings: {
    //       arrows: false,
    //       slidesToShow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 400,
    //     settings: {
    //       arrows: false,
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };

  const renderPopularCategory = () => {
    return (
      !isEmpty(businessData?.papularCategorySuccess) &&
      businessData?.papularCategorySuccess?.data?.result?.interestList.map(
        (item, index) => {
          const { id, category_name, category_image, status } = item; //destructuring
          return (
            <div
              className=""
              key={id}
              style={{
                width: "320px",
                height: "200px",
                marginLeft: index === 0 ? 0 : 20,
              }}
            >
              <img
                src={category_image}
                // className="img-fluid"
                style={{ borderRadius: "10px" }}
                height="170"
                width="180"
              />
              <p
                className="text-center"
                style={{ color: "#ffffff", fontSize: "20px", marginTop: "8px" }}
              >
                {category_name}
              </p>
            </div>
          );
        }
      )
    );
  };
  const renderNewBusinessList = () => {
    return newBusinessList?.BusinessList?.map((item, index) => {
      return (
        <Card
          key={`new_${index}`}
          width={300}
          marginLeft={index === 0 ? 0 : 20}
          marginBottom={0}
          height={380}
          data={item}
          handleViewDetail={businessPage}
          showReview
          showAddress
          showPhone
        />
      );
    });
  };

  const renderFeaturedBusinessList = () => {
    return featureBusinessList?.BusinessList?.map((item, index) => {
      return (
        <Card
          key={`feature_${index}`}
          width={300}
          marginLeft={index === 0 ? 0 : 20}
          marginBottom={0}
          height={380}
          data={item}
          handleViewDetail={businessPage}
          showReview
          showAddress
          showPhone
        />
      );
    });
  };

  const renderDailyDealsBusinessList = () => {
    return daliyDealsList?.BusinessList?.map((item, index) => {
      return (
        <Card
          key={`deal_${index}`}
          width={300}
          marginLeft={index === 0 ? 0 : 20}
          marginBottom={0}
          height={380}
          data={item}
          handleViewDetail={businessPage}
          showReview
          showAddress
          showPhone
        />
      );
    });
  };
  // console.log(businessData, 'businessData')
  const renderLocalHightlightList = () => {
    return (
      !isEmpty(businessData?.localHightlightSuccess) &&
      businessData?.localHightlightSuccess?.BusinessList.map((item, index) => {
        return (
          <Card
            key={`local_${index}`}
            width={300}
            marginLeft={index === 0 ? 0 : 20}
            marginBottom={0}
            height={380}
            data={item}
            handleViewDetail={businessPage}
            showReview
            showAddress
            showPhone
          />
        );
      })
    );
  };

  const renderBusinessType = () => {
    return (
      !isEmpty(businessType) &&
      businessType?.map((item, index) => {
        return (
          <div className="home_select_option" id={item.id} key={index}>
            {item?.business_type}
          </div>
        );
      })
    );
  };
  const businessPage = (id, b_name) => {
    navigate(
      `/businessDetails/${id}?name=${b_name?.trim()?.replaceAll(" ", "_")}`,
      { state: { id } }
    );
    // navigate(`/businessDetails/${id}`);
  };

  const businessListPage = (type) => {
    navigate(BUSINESS_LIST, { state: { type } });
  };

  const handleSelectChange = (e) => {
    if (e.target.id != "") {
      setTypeSelect(e.target.id);
      setTypeName(e.target.innerText);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 100);
    };
  };

  // useEffect(() => {
  //   searchInput.length > 0 && debouncVersion(searchInput);
  // }, [searchInput]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const getSuggestion = async (value) => {
    // setSearchInput(e.target.value);
    const query = value;
    // setValue(e.target.value.toLowerCase());
    if (query.length > 1) {
      let body = {
        user_uuid: "",
        lat: localStorage.getItem("latitude") || 21.150058,
        lng: localStorage.getItem("longitude") || 72.8548533,
        range: 50000,
        text: query,
        page: 1,
        limit: 20,
        business_type: typeSelect ? [typeSelect] : [],
      };

      let result = await dispatch(searchBusiness(body));

      if (result?.payload?.businessList.length > 0) {
        setSuggestions(result?.payload?.businessList);
        setSuggestionsActive(true);
      }
      // const filterSuggestions = data.filter(
      //   (suggestion) =>
      //     suggestion.toLowerCase().indexOf(query) > -1
      // );
      // setSuggestions(filterSuggestions);
      // setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  // const debouncVersion = useCallback(debounce(getSuggestion), [typeSelect]);

  const searchHandle = async (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadingAction(true));

    let body = {
      user_uuid: "",
      lat: localStorage.getItem("latitude") || 21.150058,
      lng: localStorage.getItem("longitude") || 72.8548533,
      range: 50000,
      text: value,
      page: 1,
      limit: 10,
      business_type: typeSelect ? [typeSelect] : [],
      business_type_name: typeName,
    };
    try {
      let result = await dispatch(searchBusiness(body));
      if (result.type === "SEARCH_BUSINESS_SUCCESS") {
        dispatch(loadingAction(false));
        navigate("/BusinessSearchResult", {
          state: { result: result.payload, body },
        });
      }
    } catch (error) {
      console.log(error, "Error");
    }
  };

  const handleClick = (e) => {
    setSuggestions([]);
    setSuggestionsActive(false);
    searchHandle(e, e.target.innerText);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setValue(suggestions[suggestionIndex]);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  const Suggestions = () => {
    return (
      <ul className="suggestions">
        {suggestions?.map((item, index) => {
          return (
            <li className="" key={index} onClick={handleClick}>
              {item.business_name}
            </li>
          );
        })}
      </ul>
    );
  };
  const handleScroll = (val) => {
    val.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <section className="br-banner" id="br-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="banner-text">
                <h5>
                  Bridging the gap between local <font>businesses</font> and{" "}
                  <font style={{ position: "relative" }}>
                    <img
                      src="images/green-suf.png"
                      className="green-suf"
                      style={{ position: "absolute" }}
                    />
                    customers.
                  </font>
                </h5>
                <ScrollAnimation animateIn="fadeInUp">
                  <form
                    className="wow fadeInUp"
                    onSubmit={(e) => searchHandle(e, searchInput)}
                  >
                    <div
                      className="main-srch homeSearchField"
                      style={{
                        alignItems: "baseline",
                        justifyContent: "center",
                      }}
                    >
                      {/* <select
                        name="Business type"
                        id="bus_type"
                        onChange={handleSelectChange}
                        style={{ width: "30%" }}
                      >
                        <option className="home_select_option" value="">
                          {" "}
                          Select Type
                        </option>
                        {renderBusinessType()}
                      </select> */}
                      <SelectBox
                        options={renderBusinessType()}
                        onChange={handleSelectChange}
                        placeholder="Business Type"
                      />
                      <ClickAwayListener
                        onClickAway={() => {
                          setSuggestions([]);
                          setSuggestionsActive(false);
                        }}
                      >
                        <div
                          className="searchInput"
                          style={{ position: "relative", width: "100%" }}
                        >
                          <input
                            type="text"
                            name="search"
                            placeholder="Search business here..."
                            onChange={handleInputChange}
                            value={searchInput}
                          />
                          {suggestionsActive && <Suggestions />}
                        </div>
                      </ClickAwayListener>
                    </div>
                    <button className="br-search-btn">Search</button>
                  </form>
                  <div className="event-explore-btn-wrap">
                    <div className="event-explore-btn">
                      <div className="event-invest-wrap">
                        <img
                          src="images/eventLogo.svg"
                          className="event-explore-logo"
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="HomeGiftTextWrapper">
                          <h3 className="events-pop-title">
                            Win <span style={{ color: "#FFD439" }}>$5000</span>{" "}
                            For Your Business!
                          </h3>

                          <button
                            style={{
                              backgroundColor: "#fff",
                              color: "#349C52",
                              padding: "8px 16px",
                              borderRadius: "6px",
                              fontSize: "1rem",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              border: "0px",
                              outline: "none",
                            }}
                            onClick={() => {
                              navigate(EVENTS);
                            }}
                          >
                            Learn More
                            <img src="images/arrow-right-g.svg" />
                          </button>
                        </div>
                        <div className="HomeGiftIcon">
                          <img
                            src="images/newgift.png"
                            className="event-explore-gift"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-image" ref={banner}>
          <p>Scroll Now</p>
          <ScrollAnimation animateIn="fadeInDown">
            <img
              src="images/scroll-img.svg"
              className="wow fadeInDown moving_vertical"
              onClick={() => handleScroll(banner)}
            />
          </ScrollAnimation>
        </div>
      </section>
      <section className="br-download-now" id="br-download-now">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h3>
                <img src="images/vector.png" className="white-suf" />
                Download now &amp; Let’s find more features to explore.
                <img src="images/suffixes.svg" className="suf1" />
              </h3>
              <p>
                Brigiz is a dedicated business search engine and business
                management platform designed to make it easy for consumers to
                conveniently connect and utilize services of local businesses.
              </p>
              <button
                className="br-explore-btn"
                onClick={() => navigate(EXPLORE_APP)}
              >
                Explore
                <ScrollAnimation animateIn="fadeInRight">
                  <img
                    style={{ bottom: "10px", left: "8rem" }}
                    src="images/arrow-img.png"
                    className="arrw-image wow fadeInRight moving_horizontal"
                  />
                </ScrollAnimation>
              </button>
              <h2>MOBILE</h2>
              <h1>BRIGIZ</h1>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="pop-category" id="popular-cat">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Popular Categories </h2>

              <button
                className="see-all-btn"
                onClick={() => businessListPage("Popular-Categories")}
              >
                See All
              </button>
            </div>
            <CustomSlider
              id="popular-slider"
              title=""
              row={true}
              browserWidth={screenWidth}
            >
              {renderPopularCategory()}
            </CustomSlider>
          </div>
        </div>
      </section> */}

      <section className="new-in-business">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>New In Business</h2>
              <button
                className="see-all-btn"
                onClick={() => businessListPage("New-Business")}
              >
                {newBusinessList?.BusinessList?.length > 4 && " See All"}
              </button>
            </div>
            {skeletonLoad[NEW_BUSINESS] ||
            newBusinessList?.BusinessList?.length > 0 ? (
              <CustomSlider
                title=""
                id="business-slider"
                row={true}
                browserWidth={screenWidth}
                isLoading={skeletonLoad[NEW_BUSINESS]}
              >
                {renderNewBusinessList()}
              </CustomSlider>
            ) : (
              <EmptyMessage />
            )}

            {/* <Slider {...newBusinessSlider}>{renderNewBusinessList()}</Slider> */}
          </div>
        </div>
      </section>

      <section className="new-in-business">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Featured Business</h2>
              <button
                className="see-all-btn"
                onClick={() => businessListPage("Featured-Business")}
              >
                {featureBusinessList?.BusinessList?.length > 4 && "See All"}
              </button>
            </div>
            {skeletonLoad[FEATURE_BUSINESS] ||
            featureBusinessList?.BusinessList?.length > 0 ? (
              <CustomSlider
                id="feature-slider"
                title=""
                row={true}
                browserWidth={screenWidth}
                isLoading={skeletonLoad[FEATURE_BUSINESS]}
              >
                {renderFeaturedBusinessList()}
              </CustomSlider>
            ) : (
              <EmptyMessage />
            )}

            {/* <Slider {...newBusinessSlider}>
              {renderFeaturedBusinessList()}
            </Slider>{" "} */}
          </div>
        </div>
      </section>
      <section className="boost-business">
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-lg-8 col-md-7">
              <div className="byb-text pe-0 py-0  position-relative">
                <h3 className=" " style={{ fontSize: "24px" }}>
                  Boost Your business Sales and growth with{" "}
                  <font>
                    Brigiz!
                    {/* <img src="images/suffixes2.svg" className="suf2" /> */}
                  </font>
                </h3>
                <div className="boost-business-dark-left">
                  <div className="p-1 px-3 align-self-center">
                    <img src="images/1.png" />
                  </div>
                  <div>
                    <h4>Reach more customers</h4>
                    <hr className="hr-green" />
                    <p className="">
                      Market to everyone within your service area. Brigiz will
                      not limit your Ads based on clicks or views.
                    </p>
                  </div>
                </div>
                <div className="boost-business-dark-right">
                  <div className="relative-div">
                    <h4>Boost Sales and Service</h4>
                    <hr className="hr-green" />
                    <p className=" pt-3">
                      Engage with customers as often as you choose. Share how
                      your products or services differ from others and what your
                      company represents in order to garner consumer support.
                    </p>
                  </div>
                  <div className="p-1 px-3 align-self-center">
                    <img src="images/2.png" />
                  </div>
                </div>{" "}
                <div className="boost-business-dark-left">
                  <div className="p-1 px-3 align-self-center">
                    <img src="images/3.png" />
                  </div>
                  <div>
                    <h4>Compete on a level playing field</h4>
                    <hr className="hr-green" />
                    <p className="">
                      With all the tools you need to compete with local and
                      national brands at your fingertips, growing your business
                      has never been easier.
                    </p>
                  </div>
                </div>
                <div className="boost-business-dark-right">
                  <div className="relative-div">
                    <h4>Increase Customer Retention</h4>
                    <hr className="hr-green" />
                    <p className=" pt-3">
                      Let us help you reach new customers and keep existing
                      customers coming back! Build customer loyalty by engaging
                      with your customers frequently.
                    </p>
                  </div>
                  <div className="p-1 px-3 align-self-center">
                    <img src="images/4.png" />
                  </div>
                </div>
                <div className="boost-business-dark-left">
                  <div className="px-3 align-self-center">
                    <img src="images/5.png" />
                  </div>
                  <div>
                    <h4>Create brand awareness and establish a network</h4>
                    <hr className="hr-green" />
                    <p className="">
                      Get your name out there while creating a supportive
                      network locally or nationwide.
                    </p>
                  </div>
                </div>
                {/* <p>
                  Whether your goal is to level the playing field as a small
                  business owner, increase your online presence, or engage and
                  interact with customers, Brigiz is the perfect way to give
                  your business the boost it needs to grow and succeed!
                </p> */}
                <img src="images/img62.png" className="img62-new" />
                {/* <p>
                  There are many variations of passages of Lorem Ipsumavailable,
                  but the majority have suffered alteration in some form, There
                  are many variations of passages.
                </p> */}
                {/* <button
                  className="byb-explore-btn"
                  onClick={() => navigate(EXPLORE_APP)}
                >
                  Explore
                </button> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="byb-img pt-5 mt-3 text-center">
                <img
                  src="images/byb.png"
                  // style={{ height: "35rem" }}
                  className="img-fluid byb-img-resposive"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="new-in-business">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Daily Deals</h2>
              <button
                className="see-all-btn"
                onClick={() => businessListPage("Daily-Deals")}
              >
                {daliyDealsList?.BusinessList?.length > 4 && "See All"}
              </button>
            </div>
            {skeletonLoad[DAILY_DEAL] ||
            daliyDealsList?.BusinessList?.length > 0 ? (
              <CustomSlider
                id="daily-slider"
                title=""
                row={true}
                browserWidth={screenWidth}
                isLoading={skeletonLoad[DAILY_DEAL]}
              >
                {renderDailyDealsBusinessList()}
              </CustomSlider>
            ) : (
              <EmptyMessage message="No sale available in your region right now. Please check after some time." />
            )}
            {/* <Slider {...newBusinessSlider}>
              {renderDailyDealsBusinessList()}
            </Slider> */}
          </div>
        </div>
      </section>

      <section className="pop-category" id="local-highlight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Local Highlights</h2>
              <button
                className="see-all-btn"
                onClick={() => businessListPage("Local-Highlights")}
              >
                {businessData?.localHightlightSuccess?.BusinessList?.length >
                  4 && "See All"}
              </button>
            </div>
            {skeletonLoad[LOCAL_HIGHLIGHT] ||
            businessData?.localHightlightSuccess?.BusinessList?.length > 0 ? (
              <CustomSlider
                id="local-slider"
                title=""
                row={true}
                browserWidth={screenWidth}
                isLoading={skeletonLoad[LOCAL_HIGHLIGHT]}
              >
                {renderLocalHightlightList()}
              </CustomSlider>
            ) : (
              <EmptyMessage />
            )}
          </div>
        </div>
        {/* <div className="wrapper">
          <div className="carousel1">
            <Slider {...localHightSetting}>
              {renderLocalHightlightList()}
            </Slider>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Home;
