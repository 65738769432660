import { axiosObj } from "./BaseApi";

export const loginApi = (data) => {
  return axiosObj.post("/users/login", data);
};

export const featuredBusinessApi = (data) => {
  return axiosObj.post("/business/homepage", data);
};

export const getPopularCatApi = (data) => {
  return axiosObj.post("/business/get_interest", data);
};

export const getBusenessDatailApi = (data) => {
  return axiosObj.post("/business/get_business_detail_by_id", data);
};

export const localHighlightApi = (data) => {
  return axiosObj.post("/business/getlocalhighlightbusinesses", data);
};

export const getNewBuisnes = (data) => {
  return axiosObj.post("/business/getnewinbusiness", data);
};

export const getDailyDeals = (data) => {
  return axiosObj.post("/business/getdailydeals", data);
};

export const getFeatureBusiness = (data) => {
  return axiosObj.post("/business/getfeaturedbusinesses", data);
};

export const contactusApi = (data) => {
  return axiosObj.post("/contactus/add_web_contact_us", data);
};

export const businessEventApi = (id) => {
  return axiosObj.get("/events/view_event/" + id);
};

export const getAllCMSData = () => {
  return axiosObj.get("/cms/all_cms_data");
};

export const getBusenessData = () => {
  return axiosObj.get("/business/get_business_detail");
};

export const subscribeApi = (data) => {
  return axiosObj.post("/contactus/add_subscribed_user/", data);
};

export const getAllPlanApi = (data) => {
  return axiosObj.post("/ad/get_all_ad_plans");
};

export const getAllBusinessApi = (data, type) => {
  if (type === "New-Business") {
    return axiosObj.post("/business/getnewinbusiness", data);
  } else if (type === "Featured-Business") {
    return axiosObj.post("/business/getfeaturedbusinesses", data);
  } else if (type === "Daily-Deals") {
    return axiosObj.post("/business/getdailydeals", data);
  } else if (type === "Local-Highlights") {
    return axiosObj.post(
      "/business/getlocalhighlightbusinesses",
      data
    );
  } else {
    return axiosObj.post("/business/getnewinbusiness", data);
  }
};

export const getBusinessTypeApi = () => {
  return axiosObj.get("/business/get_business_types_web");
};

export const searchBusinessApi = (data) => {
  return axiosObj.post("/business/search_business_details", data);
};

export const webSearchBusinessApi = (data) => {
  return axiosObj.post("/business/web_search_business_details", data);
};

export const generatePaymentIntent = (data) => {
  return axiosObj.post("/ad/generatePaymentIntentWeb", data);
};

export const getReviews = (data) => {
  return axiosObj.post("/reviews/get_app_reviews", data);
};

export const getStates = (data) => {
  return axiosObj.get("/ad/state_plan_price");
};

export const getAnnouncements = (id) => {
  return axiosObj.get(`/announcements/view_announcement/${id}`);
};

export const getSales = (id) => {
  return axiosObj.get(`/sales/view_sale/${id}`);
};
export const getSchedule = (id) => {

  return axiosObj.post('/ad/get_schedules', id)
}