import { LOADING, SKELETON_LOADER } from "./actionTypes";

export const loadingAction = (status) => {
  return (dispatch) => {
    dispatch({ type: LOADING, status });
  };
};
export const skeletonAction = (status, id) => {
  return (dispatch) => {
    dispatch({ type: SKELETON_LOADER, status, id });
  };
};
