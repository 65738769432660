import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { getBusinessList } from "../../Redux/Action/BusinessAction/businesAction";
import Card from "../../Component/Card";
import GridCardWrapper from "../../Component/GridCardWrapper";
import EmptyMessage from "../../Component/EmptyMessage";
import { BUSINESS_DETAILS } from "../../Routes/RoutesConstant";
import { BUSINESS_SEARCH } from "../../Utils/constants";
import SkeletonLoader from "../../Component/skeleton-loader/Skeleton";
import { getBusinessDetail } from "../../Redux/Action/BusinessAction/businesAction";

const SearchBusinessList = () => {
  const { id } = useParams()

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  let [responseData, setResponseData] = useState(location.state);
  let [responseData2, setResponseData2] = useState([]);

  let [isNext, isNextFunc] = useState(false);
  let [pageCount, setCount] = useState(1);

  useEffect(async () => {
    if (id) {
      let body = { business_id: id };
      const response = await dispatch(getBusinessDetail(body));
      setResponseData2(prev => [...prev, response?.payload])
    }
  }, [])

  useEffect(() => {
    if (location?.state) {
      setResponseData(location?.state)
    }
  }, [location?.state])
  // useEffect(() => {

  //     fetchData();

  // },[]);

  // useEffect(() => {
  //    console.log("------------pageCount",pageCount)
  //     fetchData();

  // },[pageCount]);

  const businessPage = (id, b_name) => {
    // navigate(BUSINESS_DETAILS, { state: { id } });
    navigate(`/businessDetails/${id}?name=${b_name?.trim()?.replaceAll(' ', '_')}`, { state: { id } });
  };

  // const fetchData = async() => {
  //     let body ={
  //         "cat_id":0,
  //         "user_uuid":"",
  //         "page":pageCount,
  //         "limit":20,
  //         "lat":21.150058,
  //         "lng":72.8548533,
  //         "text":""
  //     }
  //     let response = await dispatch(getBusinessList(body,location.state.type));
  //     // console.log("=====",response.payload.businessList);
  //     setResponseData([...responseData, ...response.payload.businessList]);
  //     isNextFunc(true);

  //   };

  // const fetchMoreData=()=> {
  //     setCount(pageCount + 1);
  //     fetchData();
  //   }

  const renderBusinessList = () => {
    // console.log("============responseData",responseData?.result?.businessList);
    if (responseData?.result?.businessList.length > 0) {
      return responseData?.result?.businessList.map((item, index) => {
        return (
          <>
            <div className="col-md-3" key={index.toString()}>
              <div className="nib-box">
                <div className="nib-box-img">
                  <img
                    src={
                      item.free_image
                        ? item.free_image
                        : item.businessImage[0].image
                    }
                    className="img-fluid"
                  />
                </div>
                <h4>{item.business_name}</h4>
                <ul>
                  {Array.from(Array(item.userReview), (e, i) => {
                    return (
                      <li>
                        <i className="fa-solid fa-star" />
                      </li>
                    );
                  })}
                </ul>
                <button
                  className="view-detail-btn"
                  onClick={() => {
                    businessPage(item.id);
                  }}
                >
                  View Details
                </button>
              </div>
            </div>
          </>
        );
      });
    }
  };

  return (
    <div>
      {!id && 
        <section className="green-heading">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  Business Search Result for "{location?.state?.body?.text ? location?.state?.body?.text : location?.state?.body?.business_type_name}"
                </h2>
                <p>Bridging the gap between local businesses and customers.</p>
              </div>
            </div>
          </div>
        </section>
      }
      {skeletonLoad[BUSINESS_SEARCH] ?
        <div style={{ display: "flex", width: '100%', overflow: 'hidden' }}>
          {Array.from({ length: 3 }, (_, index) => (
            <div style={{
              height: 380,
              width: 320,
              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-between",
              marginLeft: '8%',
              marginTop: '5%'
            }}
              key={index}>
              <SkeletonLoader height={300} />
              {/* <SkeletonLoader height={30} /> */}
              {/* <SkeletonLoader height={30} width={120} /> */}
              {/* <SkeletonLoader height={20} /> */}
              {/* <SkeletonLoader height={20} /> */}
              {/* <SkeletonLoader height={40} /> */}
            </div>
          ))}
        </div>
        :
        <section className="nib-boxes" style={{ height: "100%" }}>
          <div className="container">
            {!id ?
              <div className="row">
                {!Array.isArray(responseData?.result?.businessList) ||
                  responseData?.result?.businessList.length === 0 ? (
                  <EmptyMessage
                    message={`No Business found for the search '${location?.state?.body?.text ? location?.state?.body?.text : location?.state?.body?.business_type_name}'`}
                  />
                ) : (
                  <GridCardWrapper
                    blockWidth={335}
                    data={responseData?.result?.businessList || []}
                  >
                    {(props) => (
                      <Card
                        gridView
                        showAddress
                        showPhone
                        showReview
                        handleViewDetail={businessPage}
                        {...props}
                        width={300}
                        height={380}
                      />
                    )}
                  </GridCardWrapper>
                )}
              </div>
              :
              <div className="row">
                {!Array.isArray(responseData2) ? (
                  <EmptyMessage
                    message={`No Business found for the id: ${id}`}
                  />
                ) : (
                  <GridCardWrapper 
                    blockWidth={335} 
                    data={responseData2 || []}
                  >
                    {(props) => (
                      <Card
                        gridView
                        showAddress
                        showPhone
                        showReview
                        handleViewDetail={businessPage}
                        {...props}
                        width={300}
                        height={380}
                      />
                    )}
                  </GridCardWrapper>
                )}
              </div>
            }
          </div>
          <div className="for-border" />
        </section>
      }
    </div>
  );
};

export default SearchBusinessList;
